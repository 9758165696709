import React from 'react'
import {graphql} from 'gatsby'
import {Container} from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Features from '@solid-ui-blocks/Features/Block06'
import Services from '@solid-ui-blocks/FeaturesWithPhoto/Block01'

import Footer from '@solid-ui-blocks/Footer/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import styles from './_styles'
import ModalWithTabs from "@solid-ui-blocks/Modal/Block01/Block01";
import Hero from '@solid-ui-blocks/Hero/Block02'
import Companies from "@solid-ui-blocks/Companies/Block01";
import Contact from '@solid-ui-blocks/CallToAction/Block02'

const Camunda = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout {...props}>
            <Seo title='Camunda'/>
            {/* Modals */}
            <ModalWithTabs content={content['contact']}/>
            {/* Blocks */}
            <Header content={content['header']}/>
            <Divider space='5'/>
            <Container variant='full' sx={styles.heroContainer}>
                <Hero content={content['hero']}/>
            </Container>
            <Divider space='5'/>
            <Features content={content['features']}/>
            <Divider space='5'/>
            <Companies content={content['companies']}/>
            <Divider space='5'/>
            <Services content={content['feature-one']}/>
            <Divider space='3'/>
            <Contact content={content['cta']}/>

            {/*<Container variant='full' sx={styles.heroContainer}>*/}
            {/*  <Hero content={content['hero']} reverse />*/}
            {/*</Container>*/}
            {/*<Divider space='4' />*/}
            {/*<Companies content={content['companies']} />*/}
            {/*<Divider space='5' />*/}
            {/*<Divider space='5'/>*/}
            {/*<Container variant='full' sx={styles.buildBrandContainer}>*/}
            {/*    <BuildBrand content={content['camunda-info']}/>*/}
            {/*</Container>*/}
            {/*<Divider space='5'/>*/}
            {/*<Divider space='5' />*/}
            {/*<FeatureOne content={content['feature-one']} />*/}
            {/*<Divider space='5' />*/}
            {/*<Divider space='5' />*/}
            {/*<WhyChooseUs content={content['why-choose-us']} />*/}
            {/*<Divider space='5' />*/}
            {/*<Divider space='5' />*/}
            {/*<FeatureTwo content={content['feature-two']} reverse />*/}
            {/*<Divider space='5' />*/}
            {/*<Divider space='5' />*/}
            {/*<Stats content={content['stats']} />*/}
            {/*<Divider space='4' />*/}
            {/*<Strategies content={content['strategies']} />*/}
            {/*<Divider space='4' />*/}
            {/*<Download content={content['download']} />*/}
            {/*<Divider space='5' />*/}
            {/*<Divider space='5' />*/}
            {/*<Testimonials content={content['testimonials']} />*/}
            {/*<Divider space='5' />*/}
            {/*<Divider space='5' />*/}
            {/*<Container sx={styles.getStartedContainer}>*/}
            {/*  <GetStarted content={content['get-started']} />*/}
            {/*</Container>*/}
            {/*<Divider space='5' />*/}
            {/*<Divider space='5' />*/}
            {/*<Blog content={content['latest-blogs']} />*/}
            <Divider space='5'/>
            <Footer content={content['footer']}/>
        </Layout>
    )
}

export const query = graphql`
    query innerpageCamunda {
        allBlockContent(
            filter: { page: { in: ["site/camunda", "shared"] } }
        ) {
            nodes {
                ...BlockContent
            }
        }
    }
`
export default Camunda
